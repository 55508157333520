<template>
	<div v-loading="fullscreenLoading" v-if="orderCreateData">
		<!--收货地址-->
		<div class="item-block" v-if="orderPaymentData.is_virtual == 0">
			<div class="block-text">{{ $lang('tmdDOTco.01452', $route) }}</div>

			<div clsas="address-list">
				<div class="address-item" @click="addAddressShow">
					<div class="add-address">
						<i class="el-icon-circle-plus-outline"></i>
						{{ $lang('tmdDOTco.01453', $route) }}
					</div>
				</div>

				<div class="address-item" v-if="key < 3 || (addressShow && key >= 3)" v-for="(item, key) in memberAddress" :key="item.id"
				 :class="addressId == item.id ? 'active' : ''">
					<div class="address-info">
						<div class="options">
							<div @click="editAddress(item.id)">{{ $lang('tmdDOTco.01454', $route) }}</div>
							<template v-if="item.is_default == 0">
								<el-popconfirm :title="$lang('tmdDOTco.01455', $route)" @onConfirm="deleteMemberAddress(item.id)">
									<div slot="reference">{{ $lang('tmdDOTco.01456', $route) }}</div>
								</el-popconfirm>
							</template>
						</div>
						<div class="address-name">{{ item.name }}</div>
						<div class="address-mobile" @click="setMemberAddress(item.id)">{{ item.mobile }}</div>
						<div class="address-desc" @click="setMemberAddress(item.id)">{{ item.full_address }} {{ item.address }}</div>
					</div>
				</div>

				<div v-if="memberAddress.length > 3 && !addressShow" @click="addressShow = true" class="el-button--text address-open">
					<i class="el-icon-arrow-down"></i>
					{{ $lang('tmdDOTco.01457', $route) }}
				</div>

				<div class="clear"></div>
			</div>
		</div>

		<!--购买虚拟类商品需填写您的手机号-->
		<div class="item-block" v-if="orderPaymentData.is_virtual == 1">
			<div class="block-text">{{ $lang('tmdDOTco.01458', $route) }}</div>

			<el-form ref="form" size="mini" class="mobile-wrap" label-width="80px">
				<el-form-item :label="$lang('tmdDOTco.01459', $route)">
					<el-input :placeholder="$lang('tmdDOTco.01460', $route)" maxlength="11" v-model="orderCreateData.member_address.mobile"></el-input>
				</el-form-item>
			</el-form>
		</div>

		<!--收货地址添加-->
		<el-dialog :title="addressForm.id == 0 ? $lang('tmdDOTco.01461', $route) : $lang('tmdDOTco.01462', $route)" :visible.sync="dialogVisible" width="32%">
			<el-form ref="form" :rules="addressRules" :model="addressForm" label-width="80px">
				<el-form-item :label="$lang('tmdDOTco.01463', $route)" prop="name">
					<el-input v-model="addressForm.name" :placeholder="$lang('tmdDOTco.01464', $route)"></el-input>
				</el-form-item>

				<el-form-item :label="$lang('tmdDOTco.01465', $route)" prop="mobile">
					<el-input v-model="addressForm.mobile" maxlength="11" :placeholder="$lang('tmdDOTco.01466', $route)"></el-input>
				</el-form-item>

				<el-form-item :label="$lang('tmdDOTco.01467', $route)">
					<el-input v-model="addressForm.telephone" :placeholder="$lang('tmdDOTco.01468', $route)"></el-input>
				</el-form-item>

				<el-form-item class="area" :label="$lang('tmdDOTco.01469', $route)" prop="area">
					<el-row :gutter="10">
						<el-col :span="7">
							<el-select prop="province" ref="province" v-model="addressForm.province_id" @change="getAddress(1)" :placeholder="$lang('tmdDOTco.01470', $route)">
								<el-option :label="$lang('tmdDOTco.01471', $route)" value="0"></el-option>
								<el-option v-for="item in pickerValueArray" :key="item.id" :label="item.name" :value="item.id"></el-option>
							</el-select>
						</el-col>
						<el-col :span="7">
							<el-select ref="city" prop="city" v-model="addressForm.city_id" @change="getAddress(2)" :placeholder="$lang('tmdDOTco.01472', $route)">
								<el-option :label="$lang('tmdDOTco.01473', $route)" value="0"></el-option>
								<el-option v-for="item in cityArr" :key="item.id" :label="item.name" :value="item.id"></el-option>
							</el-select>
						</el-col>
						<el-col :span="7">
							<el-select ref="district" prop="district" v-model="addressForm.district_id" :placeholder="$lang('tmdDOTco.01474', $route)">
								<el-option :label="$lang('tmdDOTco.01475', $route)" value="0"></el-option>
								<el-option v-for="item in districtArr" :key="item.id" :label="item.name" :value="item.id"></el-option>
							</el-select>
						</el-col>
					</el-row>
				</el-form-item>

				<el-form-item :label="$lang('tmdDOTco.01476', $route)" prop="address">
					<el-input v-model="addressForm.address" :placeholder="$lang('tmdDOTco.01477', $route)"></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">{{ $lang('tmdDOTco.01478', $route) }}</el-button>
				<el-button type="primary" @click="addmemberAddress('form')">{{ $lang('tmdDOTco.01479', $route) }}</el-button>
			</span>
		</el-dialog>

		<!--使用余额-->
		<div class="item-block" v-if="orderPaymentData.member_account.balance_total > 0 && balance_show == 1">
			<div class="block-text">{{ $lang('tmdDOTco.01480', $route) }}</div>

			<div class="pay-type-list">
				<div class="pay-type-item" :class="orderCreateData.is_balance ? '' : 'active'" @click="useBalance(0)">{{ $lang('tmdDOTco.01481', $route) }}</div>
				<div class="pay-type-item" :class="orderCreateData.is_balance ? 'active' : ''" @click="useBalance(1)">{{ $lang('tmdDOTco.01482', $route) }}</div>
				<div class="clear"></div>
			</div>
		</div>

		<!-- 配送方式 -->
		<div class="item-block padd-bom-20" v-if="orderPaymentData.is_virtual == 0">
			<div class="pay-type-list">{{ $lang('tmdDOTco.01483', $route) }}
				<span class="distribution" v-if="orderPaymentData.delivery.delivery_type == 'store' && orderPaymentData.delivery.store_name">{{ orderPaymentData.delivery.store_name }}</span>
			</div>
			<div v-if="orderPaymentData.shop_goods_list.express_type.length > 0">
				<div class="pay-type-item" v-for="(deliveryItem, deliveryIndex) in orderPaymentData.shop_goods_list.express_type"
				 :key="deliveryIndex" @click="selectDeliveryType(deliveryItem)" :class="orderCreateData.delivery.delivery_type == deliveryItem.name ? 'active' : ''"
				 v-if="deliveryItem.name != 'local'">
					{{ deliveryItem.title }}
				</div>
			</div>
			<div v-else-if="memberAddress.length == 0">
				<div class="box ns-text-color">{{ $lang('tmdDOTco.01484', $route) }}</div>
			</div>
			<div v-else>
				<div class="box ns-text-color">{{ $lang('tmdDOTco.01485', $route) }}</div>
			</div>

		</div>

		<!--商品信息-->
		<div class="item-block">
			<div class="goods-list">
				<table>
					<tr>
						<td width="70%">{{ $lang('tmdDOTco.01486', $route) }}</td>
						<td width="15%">{{ $lang('tmdDOTco.01487', $route) }}</td>
						<td width="15%">{{ $lang('tmdDOTco.01488', $route) }}</td>
					</tr>
				</table>
			</div>
		</div>

		<div>
			<div class="item-block">
				<div class="goods-list">
					<table>
						<tr v-for="(goodsItem, goodsIndex) in orderPaymentData.shop_goods_list.goods_list" :key="goodsIndex">
							<td width="70%">
								<div class="goods-info">
									<div class="goods-info-left">
										<router-link :to="{ path: '/sku-' + goodsItem.sku_id }" target="_blank">
											<img class="goods-img" :src="$img(goodsItem.sku_image, { size: 'mid' })" @error="imageError(goodsIndex)" />
										</router-link>
									</div>
									<div class="goods-info-right">
										<router-link :to="{ path: '/sku-' + goodsItem.sku_id }" target="_blank">
											<div class="goods-name">{{ goodsItem.sku_name }}</div>
										</router-link>
										<!-- 规格 -->
										<div class="goods-spec" v-if="goodsItem.sku_spec_format">
											<span v-for="(x, i) in goodsItem.sku_spec_format" :key="i">{{ x.spec_value_name }} {{ i < goodsItem.sku_spec_format.length - 1 ? '; ' : '' }}</span>
										</div>
									</div>
								</div>
							</td>
							<td width="15%" class="goods-price">{{ $lang('components.currency_symbol_left', $route) + goodsItem.price + $lang('components.currency_symbol_right', $route) }}</td>
							<td width="15%" class="goods-num">{{ goodsItem.num }}</td>
						</tr>
					</table>

					<!--配送方式、留言、优惠券、店铺优惠-->
					<!-- <div class="goods-footer">
						<div class="goods-footer-left">
							<div v-if="orderPaymentData.is_virtual == 0">
								<div class="order-cell">
									<div class="tit">
										配送方式
										<span class="ns-text-color">
											<span v-if="orderPaymentData.delivery.delivery_type == 'store' && orderPaymentData.delivery.store_name">
												{{ orderPaymentData.delivery.store_name }}
											</span>
										</span>
									</div>

									<div v-if="orderPaymentData.shop_goods_list.express_type.length > 0">
										<div class="express-item" v-for="(deliveryItem, deliveryIndex) in orderPaymentData.shop_goods_list.express_type"
										 :key="deliveryIndex" @click="selectDeliveryType(deliveryItem)" :class="orderCreateData.delivery.delivery_type == deliveryItem.name ? 'active' : ''"
										 v-if="deliveryItem.name != 'local'">
											{{ deliveryItem.title }}
										</div>
									</div>
									<div v-else-if="memberAddress.length == 0">
										<div class="box ns-text-color">请先添加收货地址</div>
									</div>
									<div v-else>
										<div class="box ns-text-color">商家未配置配送方式</div>
									</div>
								</div>
							</div>

							<div v-if="orderPaymentData.shop_goods_list.coupon_list.length">
								<div class="order-cell">
									<div class="tit">优惠券</div>
									<div v-if="orderPaymentData.coupon.coupon_id">
										<span class="ns-text-color" @click="openSiteCoupon(orderPaymentData.shop_goods_list.coupon_list)">已选择优惠券，已优惠</span>
										<span class="ns-text-color" @click="openSiteCoupon(orderPaymentData.shop_goods_list.coupon_list)">
											<span class="inline">{{ $lang('components.currency_symbol_left', $route) }}</span>
											{{ orderPaymentData.coupon.coupon_money }}
											<span class="inline">{{ $lang('components.currency_symbol_right', $route) }}</span>
										</span>
									</div>

									<div v-else>
										<div class="box ns-text-color" @click="openSiteCoupon(orderPaymentData.shop_goods_list.coupon_list)">不使用优惠券</div>
									</div>
								</div>
							</div>

							<div v-if="orderPaymentData.shop_goods_list.promotion">
								<div class="order-cell">
									<div class="tit">店铺优惠</div>

									<div class="box text-overflow" v-if="orderPaymentData.shop_goods_list.promotion.manjian">
										<span class="ns-text-color">满减</span>
										<span class="ns-text-color" v-for="(item, index) in orderPaymentData.shop_goods_list.promotion.manjian" :key="index">
											{{ item.discount_array.desc }}
										</span>
									</div>

									<div class="box text-overflow" v-if="orderPaymentData.shop_goods_list.promotion.freeshipping">
										<span class="ns-text-color">满额包邮</span>

										<span class="ns-text-color" v-if="orderPaymentData.shop_goods_list.promotion.freeshipping.discount_array">
											{{ orderPaymentData.shop_goods_list.promotion.freeshipping.discount_array.desc }}
										</span>
									</div>
								</div>
							</div>

							<div>
								<div class="order-cell">
									<div class="tit">活动优惠</div>
									<div class="box text-overflow">
										<span class="ns-text-color">{{ $lang('tmdDOTco.01490', $route) + orderPaymentData.bunding_info.bl_name + $lang('tmdDOTco.01491', $route) + $lang('components.currency_symbol_left', $route) + (promotionMoney | moneyFormat) + $lang('components.currency_symbol_right', $route) }}</span>
									</div>
								</div>
							</div>
						</div>
						<div class="goods-footer-right">
							<div>
								<div class="order-cell">
									<div class="tit">买家留言</div>
									<div class="box">
										<el-input type="textarea" placeholder="留言前建议先与商家协调一致" v-model="orderCreateData.buyer_message" class="buyer-message"
										 @input="textarea" maxlength="140" show-word-limit resize="none"></el-input>
									</div>

									<span class="iconfont iconright"></span>
								</div>
							</div>
						</div>
						<div class="clear"></div>
					</div> -->
				</div>
			</div>
		</div>

		<!--优惠券-->

		<!-- <div class="item-block" v-if="orderPaymentData.shop_goods_list.coupon_list.length">
			<div class="block-text">优惠券</div>
			<div class="order-cell platform-coupon">
				<div v-if="orderPaymentData.coupon.coupon_id">
					<span class="ns-text-color" @click="openSiteCoupon(orderPaymentData.shop_goods_list.coupon_list)">已选择优惠券，已优惠</span>
					<span class="ns-text-color" @click="openSiteCoupon(orderPaymentData.shop_goods_list.coupon_list)">
						<span class="inline">{{ $lang('components.currency_symbol_left', $route) }}</span>
						{{ orderPaymentData.coupon.coupon_money }}
						<span class="inline">{{ $lang('components.currency_symbol_right', $route) }}</span>
					</span>
				</div>

				<div v-else>
					<div class="box ns-text-color" @click="openSiteCoupon(orderPaymentData.shop_goods_list.coupon_list)">不使用优惠券</div>
				</div>
			</div>
		</div> -->

		<!-- <div class="order-cell">
			<div class="tit">优惠券</div>
			<div v-if="orderPaymentData.coupon.coupon_id">
				<span class="ns-text-color" @click="openSiteCoupon(orderPaymentData.shop_goods_list.coupon_list)">已选择优惠券，已优惠</span>
				<span class="ns-text-color" @click="openSiteCoupon(orderPaymentData.shop_goods_list.coupon_list)">
					<span class="inline">{{ $lang('components.currency_symbol_left', $route) }}</span>
					{{ orderPaymentData.coupon.coupon_money }}
					<span class="inline">{{ $lang('components.currency_symbol_right', $route) }}</span>
				</span>
			</div>
		
			<div v-else>
				<div class="box ns-text-color" @click="openSiteCoupon(orderPaymentData.shop_goods_list.coupon_list)">不使用优惠券</div>
			</div>
		</div> -->



		<!--优惠-->
		<div class="item-block">
			<div class="block-text">{{ $lang('tmdDOTco.01489', $route) }}</div>
			<div class="order-cell platform-coupon">
				<span class="ns-text-color">{{ $lang('tmdDOTco.01490', $route) + orderPaymentData.bunding_info.bl_name + $lang('tmdDOTco.01491', $route) + $lang('components.currency_symbol_left', $route) + (promotionMoney | moneyFormat) + $lang('components.currency_symbol_right', $route) }}</span>
				 {{ $lang('tmdDOTco.01490', $route) + orderPaymentData.bunding_info.bl_name + $lang('tmdDOTco.01491', $route) + $lang('components.currency_symbol_left', $route) + (promotionMoney | moneyFormat) + $lang('components.currency_symbol_right', $route) }}
			</div>
		</div>

		<!-- 买家留言 -->
		<div class="item-block padd-bom-10">
			<div class="block-text">{{ $lang('tmdDOTco.01492', $route) }}</div>
			<el-input rows="3" type="textarea" :placeholder="$lang('tmdDOTco.01493', $route)" v-model="orderCreateData.buyer_message" class="buyer-message"
			 @input="textarea" maxlength="140" show-word-limit resize="none"></el-input>
		</div>
		<!-- 总计 -->
		<div class="item-block">
			<div class="order-statistics">
				<table>
					<tr>
						<td align="right">{{ $lang('tmdDOTco.01494', $route) }}</td>
						<td align="left">{{ $lang('components.currency_symbol_left', $route) + (orderPaymentData.goods_money | moneyFormat) + $lang('components.currency_symbol_right', $route) }}</td>
					</tr>
					<tr v-if="orderPaymentData.is_virtual == 0">
						<td align="right">{{ $lang('tmdDOTco.01495', $route) }}</td>
						<td align="left">{{ $lang('components.currency_symbol_left', $route) + (orderPaymentData.delivery_money | moneyFormat) + $lang('components.currency_symbol_right', $route) }}</td>
					</tr>
					<tr v-if="orderPaymentData.invoice_money > 0">
						<td align="right">{{ $lang('tmdDOTco.01496', $route) }}</td>
						<td align="left">{{ $lang('components.currency_symbol_left', $route) + (orderPaymentData.invoice_money | moneyFormat) + $lang('components.currency_symbol_right', $route) }}</td>
					</tr>
					<!-- <tr v-if="orderPaymentData.promotion_money > 0">
						<td align="right">优惠：</td>
						<td align="left">{{ $lang('components.currency_symbol_left', $route) + (orderPaymentData.promotion_money | moneyFormat) + $lang('components.currency_symbol_right', $route) }}</td>
					</tr> -->
					<!-- <tr v-if="orderPaymentData.coupon_money > 0">
						<td align="right">店铺优惠券：</td>
						<td align="left">{{ $lang('components.currency_symbol_left', $route) + (orderPaymentData.coupon_money | moneyFormat) + $lang('components.currency_symbol_right', $route) }}</td>
					</tr> -->
					<!-- {{orderPaymentData.coupon_money}} -->
					<!-- <tr v-if="orderPaymentData.coupon.coupon_money > 0">
						<td align="right">优惠券：</td>
						<td align="left">{{ $lang('components.currency_symbol_left', $route) + (orderPaymentData.coupon.coupon_money | moneyFormat) + $lang('components.currency_symbol_right', $route) }}</td>
					</tr> -->
					<tr v-if="orderPaymentData.balance_money > 0">
						<td align="right">{{ $lang('tmdDOTco.01497', $route) }}</td>
						<td align="left">{{ $lang('components.currency_symbol_left', $route) + (orderPaymentData.balance_money | moneyFormat) + $lang('components.currency_symbol_right', $route) }}</td>
					</tr>
				</table>
			</div>
			<div class="clear"></div>
		</div>

		<!--结算-->
		<div class="item-block">
			<div class="order-submit">
				<div class="order-money">
					{{ $lang('tmdDOTco.01498L', $route) + orderPaymentData.goods_num + $lang('tmdDOTco.01498R', $route)}}
					<div class="ns-text-color">{{ $lang('components.currency_symbol_left', $route) + (orderPaymentData.pay_money | moneyFormat) + $lang('components.currency_symbol_right', $route) }}</div>
				</div>
				<el-button type="primary" class="el-button--primary" @click="orderCreate">{{ $lang('tmdDOTco.01499', $route) }}</el-button>
			</div>
			<div class="clear"></div>
		</div>

		<!--配送方式-->
		<el-dialog :title="$lang('tmdDOTco.01500', $route)" :visible.sync="dialogStore" width="50%">
			<el-table ref="singleTable" :data="storeList" highlight-current-row @row-click="selectStore" class="cursor-pointer">
				<el-table-column label="" width="55">
					<template slot-scope="scope">
						<el-radio v-model="storeRadio" :label="scope.row"><i></i></el-radio>
					</template>
				</el-table-column>
				<el-table-column prop="store_name" :label="$lang('tmdDOTco.01501', $route)" width="160"></el-table-column>
				<el-table-column prop="store_address" :label="$lang('tmdDOTco.01502', $route)"></el-table-column>
				<el-table-column prop="open_date" :label="$lang('tmdDOTco.01503', $route)"></el-table-column>
			</el-table>
		</el-dialog>

		<!--选择平台优惠券弹框-->
		<el-dialog :title="$lang('tmdDOTco.01504', $route)" :visible.sync="dialogPlatcoupon" width="50%" @close="savePlatformCoupon()">
			<el-table ref="platformCouponTable" :data="orderPaymentData.platform_coupon_list" highlight-current-row @row-click="selectPlatformCoupon"
			 class="cursor-pointer">
				<el-table-column label="" width="55">
					<template slot-scope="scope">
						<div class="disabled-selected-wrap">
							<el-radio v-model="platformCouponRadio" :label="scope.row"><i></i></el-radio>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="platformcoupon_name" :label="$lang('tmdDOTco.01505', $route)" width="200"></el-table-column>
				<el-table-column prop="money" :label="$lang('tmdDOTco.01506', $route)"></el-table-column>
				<el-table-column :label="$lang('tmdDOTco.01507', $route)">
					<template slot-scope="scope">
						<span class="ns-text-color-gray ns-font-size-sm" v-if="scope.row.at_least > 0">{{ $lang('tmdDOTco.01508L', $route) + scope.row.at_least + $lang('tmdDOTco.01508R', $route) }}</span>
						<span class="ns-text-color-gray ns-font-size-sm" v-else>{{ $lang('tmdDOTco.01509', $route) }}</span>
					</template>
				</el-table-column>
				<el-table-column :label="$lang('tmdDOTco.01510', $route)">
					<template slot-scope="scope">
						<span>{{ $util.timeStampTurnTime(scope.row.end_time) }}</span>
					</template>
				</el-table-column>
			</el-table>
			<br />
			<div class="align-right">
				<el-button @click="dialogPlatcoupon = false">{{ $lang('tmdDOTco.01511', $route) }}</el-button>
				<el-button @click="dialogPlatcoupon = false" type="primary">{{ $lang('tmdDOTco.01512', $route) }}</el-button>
			</div>
		</el-dialog>

		<!--选择优惠券弹框-->
		<el-dialog :title="$lang('tmdDOTco.01513', $route)" :visible.sync="dialogCoupon" width="50%" @close="saveCoupon()">
			<el-table ref="couponTable" :data="siteCoupon.data" highlight-current-row @row-click="selectCoupon" class="cursor-pointer">
				<el-table-column label="" width="55">
					<template slot-scope="scope">
						<div class="disabled-selected-wrap">
							<el-radio v-model="couponRadio" :label="scope.row"><i></i></el-radio>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="coupon_name" :label="$lang('tmdDOTco.01514', $route)" width="200"></el-table-column>
				<el-table-column :label="$lang('tmdDOTco.01515', $route)">
					<template slot-scope="scope">
						<span v-if="scope.row.type == 'reward'">{{ scope.row.money }}</span>
						<span v-if="scope.row.type == 'discount'">{{ $lang('components.discount_left', $route) + ( $lang('common.name', $route) == 'English' ? (10 - scope.row.discount) : scope.row.discount ) + $lang('components.discount_right', $route) }}</span>
					</template>
				</el-table-column>
				<el-table-column prop="use" :label="$lang('tmdDOTco.01517', $route)"></el-table-column>
				<el-table-column prop="time" :label="$lang('tmdDOTco.01518', $route)"></el-table-column>
			</el-table>
			<br />
			<div class="align-right">
				<el-button @click="dialogCoupon = false">{{ $lang('tmdDOTco.01519', $route) }}</el-button>
				<el-button @click="dialogCoupon = false" type="primary">{{ $lang('tmdDOTco.01520', $route) }}</el-button>
			</div>
		</el-dialog>

		<!-- 配送方式 外卖 -->
		<el-dialog :title="$lang('tmdDOTco.01521', $route)" :visible.sync="deliveryTime" width="400px">
			<el-form status-icon ref="ruleForm" label-width="100px">
				<el-form-item :label="$lang('tmdDOTco.01522', $route)">
					<el-col :span="11">
						<el-time-picker format="HH:mm" value-format="HH:mm" :placeholder="$lang('tmdDOTco.01523', $route)" :value="time" v-model="time" start="09:01"
						 end="21:01" @change="bindTimeChange"></el-time-picker>
					</el-col>
				</el-form-item>
				<el-form-item label="">
					<el-col :span="20">
						<div class="align-right">
							<el-button size="small" @click="deliveryTime = false">{{ $lang('tmdDOTco.01524', $route) }}</el-button>
							<el-button type="primary" size="small" @click="setDeliveryTime(siteDelivery.site_id)">{{ $lang('tmdDOTco.01525', $route) }}</el-button>
						</div>
					</el-col>
				</el-form-item>
			</el-form>
		</el-dialog>

		<!-- 支付密码 -->
		<el-dialog :title="$lang('tmdDOTco.01526', $route)" :visible.sync="dialogpay" width="350px">
			<template v-if="orderPaymentData.member_account.is_pay_password == 0">
				<p>{{ $lang('tmdDOTco.01527', $route) }}</p>
				<p>{{ $lang('tmdDOTco.01528', $route) }}</p>
				<span slot="footer" class="dialog-footer">
					<el-button size="small" @click="dialogpay = false">{{ $lang('tmdDOTco.01529', $route) }}</el-button>
					<el-button size="small" type="primary" @click="setPayPassword">{{ $lang('tmdDOTco.01530', $route) }}</el-button>
				</span>
			</template>
			<el-form v-else status-icon ref="ruleForm" label-width="100px">
				<el-form-item :label="$lang('tmdDOTco.01531', $route)" class="pay-password-item">
					<!--添加一个不可见的input,欺骗浏览器自动填充-->
					<el-input type="password" class="pay-password hide-password" :maxlength="6"></el-input>
					<el-input type="password" class="pay-password" :maxlength="6" v-model="password" @input="input"></el-input>
				</el-form-item>
				<p class="ns-text-color forget-password" @click="setPayPassword">{{ $lang('tmdDOTco.01532', $route) }}</p>
			</el-form>
		</el-dialog>
	</div>
</template>

<script>
	import detail from './payment_combo.js';
	export default {
		name: 'combo_payment',
		mixins: [detail]
	};
</script>

<style lang="scss" scoped>
	@import './payment_combo.scss';
</style>
